import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
// import TagsInput from 'react-tagsinput';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';
import { Select } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as action from '../../../../actions/RoleAction';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from "react-toastify";

// import 'react-tagsinput/react-tagsinput.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import EEProfile from '../../Profile/EEProfile.js';
import EmployeeDetailsAndPreferenceModal from '../../1-Feed/EmployeeDetailsAndPreference/EmployeeDetailsAndPreferenceModal.js';
import { causes } from '../../../../constants/Causes'
import { OrgDataContext } from '../../../../routes.js';
// import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../config/urlConfigs.js';
import { generatePreSignedGetUrl } from '../../../../s3PresignedUrls.js';

const readCookie = require("../../../../cookie.js").readCookie;
const createCookie = require("../../../../cookie.js").createCookie;

const subdomain = configs.SUBDOMAIN;
// let subdomain = window.location.hostname.split('.')[0];
class TeamMember extends Component {
	constructor(props) {
		super(props);
		this.state = {
			member: null,
			memberData: null,
			engagements: null,
			activeMenu: "profile",
			questionsAnswered: false,
			questions: [],
			questionnaireEnabled: false,
			activeTab: 0,
			// ====Profile-States
			email: "",
			mobileNo: "",
			fullName: "",
			display_name:"",
			department: "",
			profilePicture: "",
			designationAtOrganisation: "",
			whatsapp: "",
			alternateMobileNumber: "",
			uploadingProfilePic: false,
			enableQuestionnaire: false,
			activeTab: 0,
			preference: null,
			displayPreferenceModal: false,
			userCookie: null,
			causeDisplayArray: [],
			digitalVolunteeringId: "",
		};
	}

	causeArray = subdomain.toLowerCase() === 'indusind' ? causes.indusind : causes.le;

	notifySuccess = (text) => toast.success(text);


	handleSelectedCauseDisplay = (valArr) => {
		// @Todo - to be fixed by Sachin
		if (subdomain.toLowerCase() !== 'indusind' && valArr && valArr.length > 0) {
			const dispSelectedArr = []
			for (let i = 0; i < valArr.length; i++) {
				var n = this.causeArray.indexOf(valArr[i]);
				dispSelectedArr.push(n);
			}
			this.setState({ causeDisplayArray: dispSelectedArr });
		}
	}
	notifyError = (text) => toast.error(text);

	onSave = () => {
		let data = {};
		data.fullName = this.state.fullName;
		data.mobileNo = this.state.mobileNo;
		data.department = this.state.department;
		data.profilePicture = this.state.profilePicture;
		data.designationAtOrganisation = this.state.designationAtOrganisation;
		data.whatsapp = this.state.whatsapp;
		data.alternateMobileNumber = this.state.alternateMobileNumber;
		fetch(configs.BACKEND_API_URL + "/save-user-data", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Auth: JSON.parse(readCookie("access_token")).access_token,
			},
			body: JSON.stringify(data),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (response) {
				let userData = JSON.parse(readCookie("userData"));
				userData.fullName = data.fullName;
				userData.display_name = data.display_name;
				userData.mobileNo = data.mobileNo;
				userData.department = data.department;
				userData.profilePicture = data.profilePicture;
				userData.designationAtOrganisation = data.designationAtOrganisation;
				userData.whatsapp = this.state.whatsapp;
				userData.alternateMobileNumber = this.state.alternateMobileNumber;

				delete userData.organisationData;
				delete userData.preference;
				delete userData.questionnairePreferences;
				createCookie("userData", JSON.stringify(userData), 7);
				if (response.status === "ok") {
					swal("Good job!", "Your details have been saved!", "success").then(
						function () {
							window.location.reload();
						}
					);
				} else {
					swal({
						type: "error",
						title: "Oops...",
						text: "Something went wrong!",
					}).then(function () {
						window.location.reload();
					});
				}
			});
	};

	upload = (e) => {
		let data = new FormData();
		data.append("image", e.target.files[0]);
		this.setState({ uploadingProfilePic: true });
		fetch(configs.BACKEND_API_URL + "/uploadImage", {
			method: "POST",
			headers: {
				Auth: JSON.parse(readCookie("access_token")).access_token,
			},
			body: data,
		})
			.then((data) => data.json())
			.then(
				function (data) {
					if (data && data.url) {
						this.setState({
							profilePicture: data.url,
							uploadingProfilePic: false,
						});
					}
				}.bind(this)
		).catch(err => { this.notifyError("Unsupported file format detected!"); e.target.value = null; });
	};

	onChange = (name, e) => {
		this.setState({ [name]: e.target.value });
	};

	componentDidMount() {
		let self = this;
		let userData = JSON.parse(readCookie("userData"));
		this.setState({ whatsapp: userData.whatsapp });
		this.setState({ alternateMobileNumber: userData.alternateMobileNumber });
		if (userData.preference) {
			this.setState({ preference: userData.preference })
			subdomain.toLowerCase() !== 'indusind' && this.handleSelectedCauseDisplay(userData.preference.cause);
		}
		this.setState({ userCookie: userData })

		let endPoint = (this.props.match.params.memberId && this.props.match.params.memberId !== 'profile') ? `/get-user-data/${this.props.match.params.memberId}` : "/get-user-data"
		console.log("🚀 ~ file: TeamMember.js:168 ~ TeamMember ~ componentDidMount ~ his.props.match.params.memberId && this.props.match.params.memberId !== 'profile':", this.props.match.params.memberId , this.props.match.params.memberId !== 'profile')
		fetch(configs.BACKEND_API_URL + endPoint, {
			method: "get",
			headers: {
				Auth: JSON.parse(readCookie("access_token")).access_token,
			},
		})
			.then((data) => data.json())
			.then((data) => {
				if (data.data) {
					console.log("🚀 ~ file: TeamMember.js:175 ~ TeamMember ~ .then ~ data.data:", data.data)
					let mobileNo = data.data.mobileNo || '';
					if (!mobileNo || mobileNo === "false") mobileNo = "";
					this.setState({
						email: data.data.email || "",
						fullName: data.data.fullName || "",
						display_name:data.data.display_name|| data.data.fullName || "",
						mobileNo,
						department: data.data.department || "",
						profilePicture: data.data.profilePicture,
						designationAtOrganisation: data.data.designationAtOrganisation || "",
						preference: data.data.preference ? data.data.preference : null,
						city: data.data.city || "",
						location: data.data.location || "",
						digitalVolunteeringId: data.data.digitalVolunteeringId || "",
					});
					if (!this.props.location.pathname.search('profile') !== -1 && data.data.preference) {
						this.handleSelectedCauseDisplay(data.data.preference.cause);
					} else if (this.props.location.pathname.search('profile') !== -1 && userData.preference) {
						this.setState({ preference: userData.preference })
					} else {
						this.setState({ preference: null })
					}
				}
			});
		fetch(configs.BACKEND_API_URL + '/get-questionnaire-preference-summary', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			self.setState({ enableQuestionnaire: data.data.questionnaireEnabled })
		}.bind(this));
		let apiEndPoint = this.props.location.pathname.search('profile') !== -1 ? userData.id : this.props.match.params.memberId;
		fetch(configs.BACKEND_API_URL + '/get-questionnaire-preference-summary', {
			method: "GET",
			headers: {
				'Auth': JSON.parse(readCookie('access_token')).access_token
			},
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			return data.data.questionnaireEnabled;

		}).then(function (enabled) {
			if (enabled) {
				fetch(configs.BACKEND_API_URL + '/questionnaire/userData/' + apiEndPoint,
					this.props.match.params.memberId,
					{
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
					}).then(function (response) {
						return response.json();
					}).then(function (data) {
						if (data.status == 'ok') {
							if (data.data.questionsAnswered) {
								self.setState({ questionnaireEnabled: enabled, questionsAnswered: data.data.questionsAnswered, questions: data.data.questions });
							}
							else {
								self.setState({ questionnaireEnabled: enabled, questionsAnswered: data.data.questionsAnswered })
							}
						}
					}.bind(this));
			}
		}.bind(this));


		// fetch(configs.BACKEND_API_URL + '/employee/' + apiEndPoint,
		// 	// this.props.match.params.memberId,
		// 	{
		// 		method: "GET",
		// 		headers: {
		// 			'Auth': JSON.parse(readCookie('access_token')).access_token
		// 		},
		// 	}).then(function (response) {
		// 		return response.json();
		// 	}).then(function (data) {
		// 		var member = {};
		// 		member['email'] = data.data.email;
		// 		member['name'] = data.data.fullName;
		// 		member['gender'] = data.data.gender;
		// 		member['phone'] = data.data.mobileNo;
		// 		member['employeeId'] = data.data.employeeId;
		// 		member['department'] = data.data.department;
		// 		member['designation'] = data.data.designationAtOrganisation;
		// 		member['office'] = data.data.office;
		// 		member['location'] = data.data.location;
		// 		member['addedBy'] = data.data.addedBy;
		// 		member['addedOn'] = data.data.addedOn;
		// 		this.setState({ member });
		// 	}.bind(this));

		var memberData = {};
		memberData = {
			engagementData: {
				visits: "26k (5% less than the average of 3 min)",
				timeSpent: "2min/session (5% less than the average of 2.105)",
				feedback: "100% of the projects (10% more than the average of 90%)",
				backouts: "20% of the sign-ups",
				discussions: "20 comments and likes (20% more than the average of 25)"
			}
		};

		var snapshot = {};
		fetch(configs.BACKEND_API_URL + '/ee/volunteer-opportunity/per-day-hours/' + apiEndPoint,
			// this.props.match.params.memberId,
			{
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
			}).then(function (response) {
				return response.json();
			}).then(function (voData) {
				snapshot['volunteeringProjectsCompleted'] = voData.countOfCompletedVOs;
				snapshot['volunteeringHoursClocked'] = voData.totalHoursClocked;
				snapshot['volunteerPoints'] = voData.totalPoints;
				snapshot['countOfSignedUpVOs'] = voData.countOfSignedUpVOs;
				memberData['volunteeringHoursData'] = voData.volunteeringHoursData;

				for (var i = 0; i < memberData.volunteeringHoursData.length; i++) {
					var hours = memberData.volunteeringHoursData[i].hours;
					memberData.volunteeringHoursData[i]['volunteeringHours'] = hours;
					delete memberData.volunteeringHoursData[i]['hours'];
				}

				var i = 0;
				while (i < memberData.volunteeringHoursData.length - 1) {
					var currentDate = moment(memberData.volunteeringHoursData[i].date, 'DD/MM/YYYY');
					var nextDate = moment(memberData.volunteeringHoursData[i + 1].date, 'DD/MM/YYYY');
					var dateDiff = nextDate.diff(currentDate, 'days');
					for (var j = 1; j < dateDiff; j++) {
						var date = moment(currentDate, 'DD/MM/YYYY').add(j, 'days').format('DD/MM/YYYY');
						// var hours = memberData.volunteeringHoursData[i].hours;
						var data = {
							date: date,
							volunteeringHours: 0
						};
						memberData.volunteeringHoursData.splice(i + j, 0, data);
					}
					if (dateDiff > 1) i = i + dateDiff;
					else i++;
				}

				this.setState({ tableData: voData.vounteeringTableData });
				fetch(configs.BACKEND_API_URL + '/get-per-day-contribs/' + apiEndPoint,
					// this.props.match.params.memberId,
					{
						method: "GET",
						headers: {
							'Auth': JSON.parse(readCookie('access_token')).access_token
						},
					}).then(function (response) {
						return response.json();
					}).then(function (data) {
						snapshot['givingProjectsSupported'] = data.projectsSupported;
						snapshot['givingContribution'] = data.amountDonated;
						snapshot['givingPoints'] = data.totalPoints;
						snapshot['givingMatched'] = data.amountMatched;
						memberData['snapshot'] = snapshot;
						memberData['givingData'] = data.perDayContribution;
						this.setState({ memberData, givingTableData: data.projects });
					}.bind(this));
			}.bind(this));

		fetch(configs.BACKEND_API_URL + '/get-user-engagement/' + apiEndPoint,
			// this.props.match.params.memberId,
			{
				method: "GET",
				headers: {
					'Auth': JSON.parse(readCookie('access_token')).access_token
				},
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				this.setState({ engagements: data });
			}.bind(this));
	}

	changeActiveMenu(div, value) {
		$('.ee-page-container.team-member-page').animate({
			scrollTop: $('.ee-page-container.team-member-page').scrollTop() + $('#' + div).offset().top - 120
		}, 1500);
		this.setState({ activeMenu: value });
	}

	projectName(cell, row) {
		return (
			<a href={'/opportunity/'+row.customUrl} target="_blank"><span title={cell}>{cell}</span></a>
		)
	}

	goBack() {
		window.location.pathname = '/team-management/' + this.props.match.params.listId;
	}
	// clockHours(cell,row){
	// 	console.log("row",row.clockHours)
	// 	return (
	// 		 moment().hours(Number.parseInt(row.clockHours.toString())).
	// 		 minutes((row.clockHours.toString()*60)%60).format("HH:mm").split(':')[0] + ' hours ' 
    //     	 + moment().hours(Number.parseInt(row.clockHours.toString())).
    //          minutes((row.clockHours.toString()*60)%60).format("HH:mm").split(':')[1] + ' minutes'
	// 	)

	// }

	render() {
		const volunteeringFormatter = (value) => ` ${value} Hours`;
		const givingFormatter = (value) => ` INR ${value}`;
		const options = {
			sizePerPage: 20,
			sizePerPageList: [
				{ text: '20 Per Page', value: 20 },
				{ text: '50 Per Page', value: 50 },
				{ text: '100 Per Page', value: 100 }
			]
		};

		return (
			<OrgDataContext.Consumer>
				{(organisationData) => {
					return	<div className="main-profile-member">
					<div className="ee-page-container team-member-page main-profile-member-page">
						<ul role="tablist" className="nav nav-tabs" id="nav-tab" style={{ width: '100%' }}>
							<li role="presentation" className={this.state.activeTab == 0 ? "active" : null}>
								<a
									id="settings-profile"
									role="tab"
									aria-controls="settings-profile"
									onClick={() => this.setState({ activeTab: 0 })}
								>
									<div className="settings-profile">
										<div style={{ display: "inline-block" }}></div>
										<span> Profile</span>
									</div>
								</a>
							</li>
							<li role="presentation" className={this.state.activeTab == 1 ? "active" : null}>
								<a
									id="settings-profile"
									role="tab"
									aria-controls="settings-profile"
									onClick={() => this.setState({ activeTab: 1 })}
								>
									<div className="settings-profile volunteering">
										<div style={{ display: "inline-block" }}></div>
										<span> Volunteering</span>
									</div>
								</a>
							</li>
							{organisationData
							.eePreferences.enablePayrollGiving ?
							<li role="presentation" className={this.state.activeTab == 2 ? "active" : null}>
								<a
									id="settings-profile"
									role="tab"
									aria-controls="settings-profile"
									onClick={() => this.setState({ activeTab: 2 })}
								>
									<div className="settings-profile giving">
										<div style={{ display: "inline-block" }}></div>
										<span> Giving</span>
									</div>
								</a>
							</li> 
							: null}
						</ul>
						{this.state.activeTab === 0 && <>
							<Row>
								{/* <Col md={11} lg={11} sm={12} xs={12}>
									{this.props.location.pathname.search('profile') !== -1 && <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end' }}>
										<button
											className="btn btn-primary"
											onClick={() => this.setState({ displayPreferenceModal: true })}
											style={{ marginTop: "20px" }}
										>
											Edit profile/preferences
										</button>
									</div>}
								</Col> */}
							</Row>
							<Row style={{ marginTop: 30 }}>
								<Col md={3} lg={3} sm={12} xs={12}>
									{this.state.profilePicture ? (
										<img
											className="ee-header-user-profile-img"
												src={generatePreSignedGetUrl(this.state.profilePicture)}
												// src={this.state.profilePicture}
											style={{ width: 150, height: 150, borderRadius: 75, marginLeft: 10, marginTop: 50 }}
										/>
									) : (
										<img
											className="ee-header-user-profile-img"
											src="/img/user.png"
											style={{ width: 150, height: 150, borderRadius: 75, marginLeft: 10, marginTop: 50 }}

										/>
										)}
										{!(this.props.match.params.memberId && this.props.match.params.memberId !== 'profile')  ?
											<div style={{
												display: "flex",
												flexDirection: 'row',
												justifyContent: 'flex-end'
											}}>
											<button
												className="btn btn-primary"
												onClick={() => this.setState({ displayPreferenceModal: true })}
												style={{ marginTop: "20px" }}
											>
												Edit profile/preferences
											</button>
										</div> : ( this.state.userCookie && (this.props.match.params.memberId  ===  this.state.userCookie.id) ? <div style={{
												display: "flex",
												flexDirection: 'row',
												justifyContent: 'flex-end'
											}}>
											<button
												className="btn btn-primary"
												onClick={() => this.setState({ displayPreferenceModal: true })}
												style={{ marginTop: "20px" }}
											>
												Edit profile/preferences
											</button> </div>: <>
											</>)  }
								</Col>
								<Col md={5} lg={5} sm={12} xs={12}>
									<div className="proifle-phoneNo-container-one profile-editable-container-one">
										<h3 className="profile-department">Email ID:</h3>
										<input
											className="profile-department-input"
											type="email"
											// onChange={this.onChange.bind(this, "department")}
											placeholder="Email ID"
											value={this.state.email}
											disabled
										/>
									</div>

									<div className="proifle-phoneNo-container-one profile-editable-container-one">
										<h3 className="profile-department">Full name:</h3>
										<input
											className="profile-department-input"
											type="text"
											// onChange={this.onChange.bind(this, "department")}
											placeholder="Name"
											value={this.state.fullName}
											disabled
										/>
									</div>

									<div className="proifle-phoneNo-container-one profile-editable-container-one">
										<h3 className="profile-department">Display name:</h3>
										<input
											className="profile-department-input"
											type="text"
											// onChange={this.onChange.bind(this, "department")}
											placeholder="Name"
											value={this.state.display_name}
											disabled
										/>
									</div>

									<div className="proifle-phoneNo-container-one profile-editable-container-one">
										<h3 className="profile-department">Mobile number:</h3>
										<input
											className="profile-department-input"
											type="text"
											// onChange={this.onChange.bind(this, "department")}
											placeholder="Mobile No"
											value={this.state.mobileNo}
											disabled
										/>
									</div>

									{this.state.whatsapp && <div className="proifle-phoneNo-container-one profile-editable-container-one">
										<h3 className="profile-department">WhatsApp number:</h3>
										<input
											className="profile-department-input"
											type="number"
											placeholder="WhatsApp number"
											value={this.state.whatsapp}
											disabled
										/>
										</div>}
										
										{this.state.alternateMobileNumber && <div className="proifle-phoneNo-container-one profile-editable-container-one">
											<h3 className="profile-department">Alternate number:</h3>
											<input
												className="profile-department-input"
												type="number"
												placeholder="Alternate number"
												value={this.state.alternateMobileNumber}
												disabled
											/>
										</div>}

									<div className="proifle-phoneNo-container-one profile-editable-container-one">
										<h3 className="profile-department">Department:</h3>
										<input
											className="profile-department-input"
											type="text"
											// onChange={this.onChange.bind(this, "department")}
												placeholder="Department"
												value={this.state.department}
											disabled
										/>
									</div>

									<div className="proifle-phoneNo-container-one profile-editable-container-one">
										<h3 className="profile-department">Designation:</h3>
										<input
											className="profile-department-input"
											type="text"
											// onChange={this.onChange.bind(this, "department")}
											placeholder="Designation"
											value={this.state.designationAtOrganisation}
											disabled
										/>
										</div>
										
										{this.props.role==='admin'?<div className="proifle-phoneNo-container-one profile-editable-container-one">
											<h3 className="profile-department">City:</h3>
											<input
												className="profile-department-input"
												type="text"
												// onChange={this.onChange.bind(this, "department")}
												placeholder="City"
												value={this.state.city || ""}
												disabled
											/>
										</div>:null}

										<div className="proifle-phoneNo-container-one profile-editable-container-one">
											<h3 className="profile-department">Office:</h3>
											<input
												className="profile-department-input"
												type="text"
												// onChange={this.onChange.bind(this, "department")}
												placeholder="office"
												value={this.state.location || ""}
												disabled
											/>
										</div>

										{this.props.role==='admin' || (this.state.digitalVolunteeringId && !(this.props.match.params.memberId && this.props.match.params.memberId !== 'profile')) ? <div className="proifle-phoneNo-container-one profile-editable-container-one">
											<h3 className="profile-department">IBL Volunteer Number:</h3>
											<input
												className="profile-department-input"
												type="text"
												// onChange={this.onChange.bind(this, "department")}
												placeholder="digitalVolunteeringId"
												value={this.state.digitalVolunteeringId || ""}
												disabled
											/>
											<CopyToClipboard text={this.state.digitalVolunteeringId}
												onCopy={() => this.notifySuccess('Volunteer ID copied!')}
												title='Click to copy this ID'
											>
												<i class="fa fa-copy" style={{ marginLeft: '2px', cursor: 'pointer' }} title='Click to copy this ID'/>
												</CopyToClipboard>
										</div> : null}
								</Col>
								{this.state.preference
									&& <Col md={4} lg={4} sm={12} xs={12} style={{ marginTop: 30 }}>
										<div style={{ maxWidth: 400 }}>
											<h3 className="profile-phoneNo" style={{ fontSize: 16, alignSelf: 'flex-start' }}>Skills I can contribute to :</h3>
											<Select
												mode="multiple"
												style={{ width: '100%' }}
												size='large'
												placeholder="Please select"
												value={this.state.preference && this.state.preference.skills}
												disabled
											/>
										</div>
										<div style={{ maxWidth: 400, marginTop: 20 }}>
											<h3 className="profile-phoneNo" style={{ fontSize: 16, alignSelf: 'flex-start' }}>Causes I would like to support :</h3>
											{subdomain.toLowerCase() === 'indusind' ?
												<Select
													mode="multiple"
													style={{ width: '100%' }}
													size='large'
													placeholder="Please select"
													value={this.state.preference && this.state.preference.cause}
													disabled
												/>
												: <Row>
													{this.state.causeDisplayArray.map(x => <Col md={3} style={{ padding: 0 }}>
														<div style={{ padding: 10 }}>
															<img src={`/img/cause/goal-${x + 1}.png`} style={{ width: '100%', height: '100%' }} />
														</div>
													</Col>)}
												</Row>}
										</div>
									</Col>}
							</Row>
							<EmployeeDetailsAndPreferenceModal allUserData={this.state} show={this.state.displayPreferenceModal} close={() => this.setState({ displayPreferenceModal: false })} editMode />
						</>}
						{this.state.activeTab == 1 && <div style={{ width: '100%' }}><div className="container-header volunteering-snapshot">Volunteering</div>
							{this.state.memberData !== null ? (
								<Row className="user-profile-details-container">
									<Col md={12} id="volunteering-snapshot-container">
										<Col md={3}>
											<div className="single-stat mb10">
												{/* <img src="/img/icons/hold.png" /> */}
												<img src="/img/IBL/volunteer.png" />
												<div>
													{this.state.memberData.snapshot.volunteeringProjectsCompleted + ' Volunteering Projects Completed'}
												</div>
											</div>
										</Col>

										<Col md={3}>
											<div className="single-stat mb10">
												{/* <img src="/img/icons/trophy.png" /> */}
												<img src="/img/IBL/trophy.png" />
												<div>
												{ moment().hours(Number.parseInt(this.state.memberData.snapshot.volunteeringHoursClocked.toString())).
                          						  minutes((this.state.memberData.snapshot.volunteeringHoursClocked.toString()*60)%60).format("HH:mm").split(':')[0] + ' Hours ' 
                         						  + moment().hours(Number.parseInt(this.state.memberData.snapshot.volunteeringHoursClocked.toString())).
                         						  minutes((this.state.memberData.snapshot.volunteeringHoursClocked.toString()*60)%60).format("HH:mm").split(':')[1] + ' Minutes' + ' My Volunteer Hours'}
												</div>
											</div>
										</Col>

										<Col md={3}>
											<div className="single-stat mb10">
												{/* <img src="/img/icons/clock.png" /> */}
												<img src="/img/IBL/clock.png" />
												<div>
													{this.state.memberData.snapshot.countOfSignedUpVOs + ' Volunteering Projects Upcoming'}
												</div>
											</div>
										</Col>
										{organisationData.eePreferences.enableGoodwillPoint ?
											<Col md={3}>
											<div className="single-stat mb10">
												{/* <img src="/img/icons/trophy.png" /> */}
												<img src="/img/IBL/trophy.png" />
												<div>
													{this.state.memberData.snapshot.volunteerPoints + ' Goodwill Points'}
												</div>
											</div>
										</Col>:null}
									</Col>
								</Row>
							) : (null)}
							{this.state.memberData !== null ? (
								<div>
									<Row className="user-profile-details-container">
										<Col md={12} id="volunteering-container">
											<div className="linechart-container volunteering-hours-graph-container" style={{ height: 300 }}>
												<ResponsiveContainer width='100%' height='100%'>
													<LineChart responsive style={{ margin: 10 }} data={this.state.memberData.volunteeringHoursData}>
														<CartesianGrid strokeDasharray="3 3" />
														<XAxis dataKey="date" />
														<YAxis tickFormatter={volunteeringFormatter} />
														<Tooltip />
														<Line type="monotone" dataKey="volunteeringHours" stroke="#ef5a20" strokeWidth={2} />
													</LineChart>
												</ResponsiveContainer>
											</div>
										</Col>
									</Row>
									<div className="user-profile-details-container">
										<Col md={12} id="volunteering-container">
											<BootstrapTable data={this.state.tableData} options={options} striped={false} 
											// ref='table'
											 hover={true}>
												<TableHeaderColumn dataField="date" isKey={true} hidden={false} export={false} searchable={false} width="110">Date</TableHeaderColumn>
												<TableHeaderColumn dataField="title" hidden={false} export={true} searchable={true} dataFormat={this.projectName.bind(this)} >Project</TableHeaderColumn>
													<TableHeaderColumn dataField="status" hidden={false} export={true} searchable={true} width="140">Status</TableHeaderColumn>
													<TableHeaderColumn dataField="clockedHours" hidden={false} export={true} searchable={true} width="140">Hours Clocked</TableHeaderColumn>
												{/* <TableHeaderColumn dataField="declaration" hidden={false} export={true} searchable={true}>Declaration</TableHeaderColumn>
												<TableHeaderColumn dataField="reviewed" hidden={false} export={true} searchable={true}>Reviewed</TableHeaderColumn> */}
											</BootstrapTable>
										</Col>
									</div>
								</div>
							) : (null)}
						</div>}
						{this.state.activeTab === 2 && <>
							<div className="container-header giving-snapshot">Giving</div>
							{this.state.memberData !== null ? (
								<Row className="user-profile-details-container">
									<Col md={12} id="giving-snapshot-container">
										<Col md={3}>
											<div className="single-stat mb10">
												<img src="/img/icons/idea.png" />
												<div>
													{this.state.memberData.snapshot.givingProjectsSupported + ' Projects Supported'}
												</div>
											</div>
										</Col>

										<Col md={3}>
											<div className="single-stat mb10">
												{/* <img src="/img/icons/care.png" /> */}
												<img src="/img/IBL/care.png" />
												<div>
													{this.state.memberData.snapshot.givingContribution + ' Projects Completed'}
												</div>
											</div>
										</Col>

										<Col md={3}>
											<div className="single-stat mb10">
												<img src="/img/icons/donation.png" />
												<div>
													{'INR ' + this.state.memberData.snapshot.givingMatched + ' Matched'}
												</div>
											</div>
										</Col>
                                       {organisationData.eePreferences.enableGoodwillPoint?
										<Col md={3}>
											<div className="single-stat mb10">
												{/* <img src="/img/icons/trophy.png" /> */}
												<img src="/img/IBL/trophy.png" />
												<div>
													{this.state.memberData.snapshot.givingPoints + ' Goodwill Points'}
												</div>
											</div>
										</Col> 
												:null	}
									</Col>
								</Row>
							) : (null)}
							{this.state.questionnaireEnabled !== null && this.state.questionnaireEnabled ? (
								<React.Fragment>
									<div className="container-header giving-snapshot">Volunteering Preferences</div>

									<Row className="user-profile-details-container">
										<Col md={12} id="volunteering-preferences-container">
											{this.state.questionsAnswered ? (this.state.questions.map((question, index) => {
												return question.checked ? <Col md={12} key={index}>
													<Row><strong>{question.question}</strong></Row>
													<Row>{question.type === 'singleselect' ? question.selected : question.selected.join(', ')}</Row>
													<hr /></Col> : null
											})) : <Col><strong>OOPS! This user hasn't taken the Volunteer Preferences Survey yet!</strong></Col>}
										</Col>
									</Row>
								</React.Fragment>
							) : (null)}
							{this.state.memberData !== null ? (
								<div>
									<Row className="user-profile-details-container">
										<Col md={12} id="giving-container">
											<div className="linechart-container giving-hours-graph-container" style={{ height: 300 }}>
												<ResponsiveContainer width='100%' height='100%'>
													<LineChart responsive style={{ margin: 10 }}
														data={this.state.memberData.givingData}>
														<XAxis dataKey="date" />
														<YAxis tickFormatter={givingFormatter} />
														<CartesianGrid strokeDasharray="3 3" />
														<Tooltip />
														<Line type="monotone" dataKey="amount" stroke="#ef5a20" activeDot={{ r: 8 }} />
													</LineChart>
												</ResponsiveContainer>
											</div>
										</Col>
									</Row>

									<div className="user-profile-details-container">
										<Col md={12} id="giving-container">
											<BootstrapTable data={this.state.givingTableData} options={options} striped={false} 
											// ref='table'
											 hover={true}>
												<TableHeaderColumn dataField="date" isKey={true} hidden={false} export={false} searchable={false}>Date</TableHeaderColumn>
												<TableHeaderColumn dataField="title" hidden={false} export={true} searchable={true} dataFormat={this.projectName.bind(this)}>Project</TableHeaderColumn>
												<TableHeaderColumn dataField="contribution" hidden={false} export={true} searchable={true}>Contribution</TableHeaderColumn>
												<TableHeaderColumn dataField="matched" hidden={false} export={true} searchable={true}>Matched</TableHeaderColumn>
											</BootstrapTable>
										</Col>
									</div>
								</div>
							) : (null)}

							{this.state.engagements !== null ? (
								<div>
									<div className="container-header">Engagement</div>
									<Row className="user-profile-details-container">
										<Col md={12} id="engagement-container">
											<Col md={7} className="engagements">
												<div className="engagement">
													<div className="engagement-circle" style={{ backgroundColor: '#4dce4d' }}></div>
													<span className="engagement-heading">Project feedback:</span>
													{this.state.engagements.feedbackPercentage}
												</div>
												<div className="engagement">
													<div className="engagement-circle" style={{ backgroundColor: '#f98344' }}></div>
													<span className="engagement-heading">Project backouts:</span>
													{this.state.engagements.backedOutPercentage}
												</div>
												<div className="engagement">
													<div className="engagement-circle" style={{ backgroundColor: '#f9d644' }}></div>
													<span className="engagement-heading">discussions:</span>
													{this.state.engagements.totalComments}
												</div>
											</Col>
										</Col>
									</Row>
								</div>
							) : (null)}
						</>}
						<div className="mb50 mt30"></div>
						</div>
						<ToastContainer
							position="bottom-center"
							autoClose={50000}
							hideProgressBar={true}
							closeOnClick={false}
							newestOnTop={false}
							pauseOnHover={true}
						/>
				</div>
				}}
			</OrgDataContext.Consumer>
		);
	}
}

function mapStateToProps(state) {

	return {
		// selectedOpportunity: state.EEDiscoverOpportunity.selectedOpportunity,
		// commentText: state.EEDiscoverOpportunity.commentText,
		// commentImage: state.EEDiscoverOpportunity.commentImage,
		// toggleCommentPost: state.EEDiscoverOpportunity.toggleCommentPost,
		// subCommentText: state.EEDiscoverOpportunity.subCommentText,
		// commentId: state.EEDiscoverOpportunity.commentId,
		// participantsData: state.EEDiscoverOpportunity.participantsData,
		role: state.RoleReducer,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(action, dispatch),
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(TeamMember);
