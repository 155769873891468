import React, { useState, useEffect, useReducer, useContext } from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { readCookie } from '../../../../../cookie';
import { OrgDataContext } from '../../../../../routes';
import moment from "moment";
import configs from "../../../../../config/urlConfigs";
const screenWidth = window.innerWidth;

const styles = {
  paddingLeft: screenWidth < 768 ? '15px' : '0px'
};

const subdomain = configs.SUBDOMAIN;
const FamilyCertificateModal = ({ show, hideModal, opportunity,  guestList,index }) => {
    const organisationData = useContext(OrgDataContext);
    const [friendsList, setFriendsList] = useState([]);

    let attendee = opportunity.attendees[index];
    let attendeeId = attendee['id'];
    useEffect(() => {
        guestList.map((data) => {
            if (data.volunteerId === attendeeId) {
                setFriendsList(data.friends)
            }
        })
    }, [attendee])

const generateCertificateForFriends = (friend) => {
    let voUserData ={};
    if(opportunity.physicalOpportunity){
      voUserData['physicalOpportunity'] = true
      voUserData['city'] = opportunity.city;
    } else {
      voUserData['physicalOpportunity'] = false
      voUserData['city'] = ""
    }
    voUserData['id'] = friend._id
    voUserData['title'] =  opportunity.title;
    voUserData['name'] = friend.name;
    voUserData['date'] = moment(opportunity.date,'DD/MM/YYYY').format('DD MMM YYYY');
    fetch( configs.BACKEND_API_URL + `/ee/friends/certificate/${opportunity.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Auth: JSON.parse(readCookie("access_token"))
              .access_token,
          },
          body: JSON.stringify({voUserData}),
        }
      )
        .then((response) => response.json())
        .then(
          function (data) {
            var a = document.createElement('a');
            a.href = data.pdfUrl;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
          }
        );



}
const getCertificate =()=> {
    let voId = opportunity.id
    fetch(
      configs.BACKEND_API_URL +
      '/ee/volunteer-opportunity/get-certificates/' +
      voId + '/' + attendeeId,
      {
        method: "GET",
        headers: {
          'Auth': JSON.parse(readCookie("access_token")).access_token,
        },
      }
    )
      .then((data) => data.json())
      .then(function (data) {
        var a = document.createElement('a');
        a.href = data.pdfUrl;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
      }
      )
  }


    return (
        <Modal show={show} onHide={hideModal} bsSize='lg'>
            <Modal.Header closeButton>
                <Modal.Title><strong>Certificate </strong></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{paddingLeft:'30px'}} bsSize='lg'>
                <Row style={{margin: 0 }}>
               
                <Col md={5} sm={5} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }}  >Name</label>
                    </Col>
                    <Col md={3} sm={3} style={{ padding: 0 }}>
                        <label className="control-label ml5" style={{ fontWeight: 'bold' }}  >View Certificate</label>
                    </Col>
                    <Row style={styles}>
                    <Col md={5} sm={5} style={{ padding: 0 }}>
                    <label className="control-label ml5" style={{ fontWeight: 'bold' }}  >{attendee.fullName}</label>
                    </Col>
                    <Col md={3} sm={3} style={{ padding: 0 }}>
                        <label className="control-label ml5 btn-sq " style={{padding: "3px", color: 'white', background : configs.THEME_COLOR ,cursor:'pointer'}}
                         onClick={getCertificate} >View Certificate</label>
                    </Col>
                    </Row>
                    {friendsList && friendsList.length > 0 && friendsList?.map((friend, index) =>
                    
                        friend?.attended === true ?
                            <div key={index}>
                            <Col md={5} sm={5} style={{ padding: 0 }}>
                                <label className="control-label ml5" style={{ fontWeight: 'bold' }}  >{friend?.name}</label>
                            </Col>
                            <Col md={3} sm={3} style={{ padding: 0 }}>
                                <label className="control-label ml5 btn-sq " style={{padding: "3px", color: 'white', background : configs.THEME_COLOR,cursor:'pointer' }} 
                                onClick={()=>generateCertificateForFriends(friend)} >View Certificate</label>
                            </Col>
                        </div>
                      : null
                    )}
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default FamilyCertificateModal