import React,{useState} from 'react'
import { Check } from 'react-feather';
import CropImage from "./CropImage/CropImage";

const Step1ProfileInfo = ({ user,display_name, onUpload, profile, mobile, editMode, whatsapp, handleBasic, checked, handleCheck, uploadingProfile, alternateMobileNumber ,imageData,showCheckboxProfilePicture}) => {
   
      const [src,setSrc] = useState(null);
      const [preview, setPreview] = useState(null);
     return (
        <form style={{ marginBottom: 50 }}>
            <div className="form-group" style={{marginBottom:7}}>
                <label for="exampleInputEmail1">Full Name</label>
                <input type="email" className="form-control" value={user.fullName} placeholder="Full name" disabled />
            </div>

            <div className="form-group" style={{marginBottom:7}}>
                <label for="exampleInputEmail1">Email Address</label>
                <input type="email" className="form-control" value={user.email} placeholder="email" disabled />
            </div>
            <div className="form-group" style={{marginBottom:7}}>
                <label for="exampleInputEmail1">Display Name<span style={{ color: 'grey', fontWeight: 'light' }}>(This name will appear on the certificates of participation.)</span></label>
                <input type="text" className="form-control" id='display_name'  onChange={(e) => handleBasic(e)} value={display_name} placeholder="Enter Display Name" />
            </div>

            <div className="form-group" style={{marginBottom:7}}>
                <label for="exampleInputEmail1" className="control-label is-imp">Mobile Number</label>
                 <input type="text" value={mobile} className="form-control"
                    //  disabled
                     placeholder="mobile" id='mobile'
                     onChange={(e) => handleBasic(e)}
                     maxlength="10" />
            </div>

            <div className="form-group" style={{marginBottom:7}}>
                <label for="exampleInputEmail1">WhatsApp Number <span style={{ color: 'grey', fontWeight: 'light' }}>(If WhatsApp number is different from the mobile number above)</span></label>
                <input type="number" className="form-control" placeholder="optional" value={whatsapp} id='whatsapp' onChange={(e) => handleBasic(e)} maxlength="10" />
            </div>

             <div className="form-group" style={{marginBottom:7}}>
                 <label for="exampleInputEmail1">Alternate Number <span style={{ color: 'grey', fontWeight: 'light' }}>(if any)</span></label>
                 <input type="number" className="form-control" placeholder="optional" value={alternateMobileNumber} id='alternateMobileNumber' onChange={(e) => handleBasic(e)} maxlength="10" />
             </div>

            <CropImage imageData={(data)=>imageData(data) } showCheckboxProfilePicture={(data)=>showCheckboxProfilePicture(data)}/>

                {/* <input type="file"  multiple={false} accept="image/*"className="form-control-file" id="profile" onChange={(e)=> onUpload(e)} />  */}
            {/* </div>
            {profile && !uploadingProfile && <img src={profile} style={{ width: 100, height: 100, borderRadius: 50, borderWidth: 1, borderColor: 'lightgrey' }} />} */}

            <div className="form-group form-check" style={{ display: 'flex', flexDirection: 'row',marginTop:55 }}>
                <a className={checked ? 'checkbox-active' : 'checkbox-inactive'} style={{ paddingLeft: 2, padddingRight: 2}} onClick={() => handleCheck(!checked)}>
                    <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                </a>
                <label className="form-check-label" style={{ marginLeft: 5 }} for="exampleCheck1">
                    I agree to receive notifications and updates over SMS/WhatsApp, pertaining to <span className='organizationName' />’s Employee Volunteering <br></br>Program and CSR.
                </label>
            </div>
        </form>
    )
}

export default Step1ProfileInfo